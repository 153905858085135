<template>
  <div
    class="home"
    :style="{
      '--width': mode ? '100px' : '200px',
      '--height': mode ? '50px' : '200px',
      '--font-size': mode ? '0.7rem' : '1rem',
    }"
  >
    <div
      class="hexagons-container buttons"
      :class="{
        init: targetHeaders.length && sourceHeaders.length,
      }"
    >
      <div class="dropzones-container hexagon-row">
        <div
          class="dropzone source hexagon"
          :class="{ active: sourceFilename }"
          @drop.prevent="dropSource"
          @dragover.prevent
        >
          <template v-if="sourceFilename">{{ sourceFilename }}</template>
          <label v-else>
            source
            <input type="file" @change="handleSourceSelect" />
          </label>
        </div>
        <div
          class="dropzone target hexagon"
          :class="{ active: targetFilename }"
          @drop.prevent="dropTarget"
          @dragover.prevent
        >
          <template v-if="targetFilename">{{ targetFilename }}</template>
          <label v-else>
            target
            <input type="file" @change="handleTargetSelect" />
          </label>
        </div>
      </div>
      <div class="hexagon-row">
        <div
          v-if="targetHeaders.length && sourceHeaders.length"
          class="button hexagon"
          :class="{ active: mode === 'mapping' }"
          @click="mode = mode !== 'mapping' ? 'mapping' : null"
          @drop.prevent="dropMapping"
          @dragover.prevent
        >
          mapping
        </div>
      </div>
      <div class="hexagon-row">
        <div
          v-show="targetHeaders.length && sourceHeaders.length"
          class="button hexagon"
          :class="{ active: mode === 'grouping' }"
          @click="mode = mode !== 'grouping' ? 'grouping' : null"
          @drop.prevent="dropGrouping"
          @dragover.prevent
        >
          grouping
        </div>
        <div
          v-show="targetHeaders.length && sourceHeaders.length"
          class="button hexagon"
          :class="{ active: mode === 'preview' }"
          @click="togglePreview"
        >
          preview
        </div>
      </div>
      <div
        v-if="targetHeaders.length && sourceHeaders.length"
        class="hexagon-row"
      >
        <div class="button process hexagon" @click="process(true)">
          Download
        </div>
      </div>
      <div
        v-if="targetHeaders.length && sourceHeaders.length"
        class="hexagon-row"
        @click="mode = mode !== 'export-settings' ? 'export-settings' : null"
      >
        <div class="button hexagon">Settings</div>
      </div>
    </div>
    <div v-if="mode === 'mapping'" class="mapping">
      <button @click="downloadMapping">Download</button>
      <button @click="deleteMapping">Delete</button>
      <table>
        <thead>
          <tr>
            <th>Target</th>
            <th>Source</th>
            <th>Preview</th>
          </tr>
        </thead>
        <tbody v-if="targetHeaders.length">
          <tr v-for="col of targetHeaders" :key="col">
            <td class="target">{{ col }}</td>
            <td class="source inputs">
              <div>
                <select
                  v-if="sourceHeaders.length"
                  @change="setMapping(col, $event.target.value)"
                >
                  <option :value="null">empty or custom</option>
                  <option
                    v-for="field of sourceHeaders"
                    :key="`${col}-${field}`"
                    :value="field"
                    :selected="mappings[col].col === field"
                  >
                    {{ field }}
                  </option>
                </select>
                <input
                  v-if="!mappings[col].col"
                  type="text"
                  @input="setCustomMapping(col, $event.target.value)"
                />
              </div>
            </td>
            <td class="preview">
              {{ getPreview(col) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="mode === 'preview'" class="preview">
      <table class="preview">
        <thead>
          <tr>
            <th v-for="h of targetHeaders" :key="`preview-th-${h}`">{{ h }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, i) of mappedResult" :key="`result-row-${i}`">
            <td v-for="(val, col) in row" :key="`result-${i}-${col}`">
              <input
                v-if="editCell && editCell.row === i && editCell.col === col"
                v-model="mappedResult[i][col]"
                type="text"
                ref="input"
                @blur="editCell = null"
              />
              <span v-else @click="toggleEdit(i, col)">
                {{ val }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="mode === 'grouping'" class="grouping">
      <button @click="downloadGrouping">Download</button>
      <button @click="deleteGrouping">Delete</button>
      <table>
        <thead>
          <tr>
            <th>Column</th>
            <th>Active</th>
            <th>Mode</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="h of sourceHeaders" :key="`group-${h}`">
            <td>{{ h }}</td>
            <td>
              <input
                :value="h"
                :checked="groupFields.includes(h)"
                type="checkbox"
                @change="handleGrouping(h)"
              />
            </td>
            <td class="inputs">
              <div>
                <select
                  v-if="groupFields.length"
                  @change="handleGroupingType(h, $event.target.value)"
                >
                  <option
                    v-for="opt of groupModes"
                    :key="`group-type-${h}-${opt.value}`"
                    :selected="isGroupModeSelected(h, opt.value)"
                    :value="opt.value"
                  >
                    {{ opt.name }}
                  </option>
                </select>
                <input
                  v-if="groupTypes[h] && groupTypes[h].value === 'concat'"
                  :value="groupTypes[h].glue"
                  type="text"
                  @change="handleGlueInput(h, $event.target.value)"
                />
                <input
                  v-if="groupTypes[h] && groupTypes[h].value === 'count'"
                  :value="groupTypes[h].start"
                  type="number"
                  step="1"
                  @change="handleStartInput(h, $event.target.value)"
                />
                <input
                  v-if="groupTypes[h] && groupTypes[h].value === 'transpose'"
                  :value="groupTypes[h].start"
                  type="number"
                  step="1"
                  @change="handleTransposeStartInput(h, $event.target.value)"
                />
                <input
                  v-if="groupTypes[h] && groupTypes[h].value === 'transpose'"
                  :value="groupTypes[h].target"
                  type="text"
                  @change="handleTransposeTargetChange(h, $event.target.value)"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="mode === 'export-settings'" class="export-settings">
      <label>
        delimiter
        <input v-model="exportSettings.delimiter" placeholder="auto-detect" />
        <span @click="exportSettings.delimiter = '\t'">tab</span>
      </label>
      <label>
        newline
        <select v-model="exportSettings.newline">
          <option :selected="exportSettings.newline === '\r\n'" :value="'\r\n'">
            \r\n
          </option>
          <option :selected="exportSettings.newline === '\r'" :value="'\r'">
            \r
          </option>
        </select>
      </label>
    </div>
  </div>
</template>

<script>
import Papa from 'papaparse';
import sum from 'hash-sum';

export default {
  data() {
    return {
      editCell: null,
      mappingLoaded: false,
      groupTypesLoaded: false,
      groupFieldsLoaded: false,
      sourceFile: null,
      sourceHeaders: [],
      sourceFilename: null,
      targetFile: null,
      targetHeaders: [],
      targetFilename: null,
      sourceContent: [],
      mappings: {},
      groupFields: [],
      groupTypes: {},
      mode: null,
      mappedResult: null,
      showLeftSidebar: false,
      settingsHash: null,
      exportSettings: {
        quotes: false, //or array of booleans
        quoteChar: '"',
        escapeChar: '"',
        delimiter: ';',
        header: true,
        newline: '\r\n',
        skipEmptyLines: false, //other option is 'greedy', meaning skip delimiters, quotes, and whitespace.
        columns: null, //or array of strings
      },
      groupModes: [
        { value: 'first', name: 'FIRST occurrence' },
        { value: 'last', name: 'LAST occurrence' },
        { value: 'add', name: 'Add' },
        { value: 'subtract', name: 'Subtract' },
        { value: 'concat', name: 'Concatenate', glue: ', ' },
        { value: 'count', name: 'Count', start: 1 },
        {
          value: 'transpose',
          name: 'Transpose',
          target: '',
          start: 1,
          cur: 1,
          counts: {},
        },
      ],
    };
  },
  methods: {
    async toggleEdit(row, col) {
      if (this.editCell?.row === row && this.editCell?.col === col) {
        this.editCell = null;
      } else {
        this.editCell = { row, col };
        await this.$nextTick();
        this.$refs.input[0].focus();
      }
    },
    dropMapping(e) {
      const reader = new FileReader();
      const vm = this;
      const handleFileRead = function (e) {
        const contents = e.target.result;
        const mapping = JSON.parse(contents);
        const cleanMapping = {};
        for (const field in mapping) {
          if (vm.targetHeaders.includes(field)) {
            cleanMapping[field] = mapping[field];
          }
        }
        vm.mappings = cleanMapping;
        reader.removeEventListener('load', handleFileRead);
      };
      reader.addEventListener('load', handleFileRead);
      reader.readAsText(e.dataTransfer.files[0]);
    },
    dropGrouping(e) {
      const reader = new FileReader();
      const vm = this;
      const handleFileRead = function (e) {
        const contents = e.target.result;
        const groups = JSON.parse(contents);
        vm.groupFields =
          groups?.fields?.filter?.((f) => vm.sourceHeaders.includes(f)) ?? [];
        const cleanTypes = {};
        for (const field in groups.types) {
          if (vm.sourceHeaders.includes(field)) {
            cleanTypes[field] = groups.types[field];
          }
        }
        vm.groupTypes = cleanTypes;
        reader.removeEventListener('load', handleFileRead);
      };
      reader.addEventListener('load', handleFileRead);
      reader.readAsText(e.dataTransfer.files[0]);
    },
    downloadMapping() {
      const contents = JSON.stringify(this.mappings);
      const hash = this.getSettingsHash();
      this.downloadFile(`csv-converter-mapping-${hash}.json`, contents);
    },
    deleteMapping() {
      const hash = this.getSettingsHash();
      localStorage.removeItem(`mapping-${hash}`);
    },
    deleteGrouping() {
      const hash = this.getSourceHash();
      console.log(`grouping-types-${hash}`, `grouping-fields-${hash}`);
      localStorage.removeItem(`grouping-types-${hash}`);
      localStorage.removeItem(`grouping-fields-${hash}`);
    },
    downloadGrouping() {
      const contents = JSON.stringify({
        types: this.groupTypes,
        fields: this.groupFields,
      });
      const hash = this.getSettingsHash();
      this.downloadFile(`csv-converter-grouping-${hash}.json`, contents);
    },
    handleGlueInput(col, value) {
      this.groupTypes[col].glue = value;
      this.saveGroupingTypes();
    },
    handleStartInput(col, value) {
      const num = Number(value);
      if (isNaN(num)) {
        this.groupTypes[col].start = 1;
      } else {
        this.groupTypes[col].start = num;
      }
      this.saveGroupingTypes();
    },
    handleTransposeTargetChange(col, value) {
      this.groupTypes[col].target = value;
      this.saveGroupingTypes();
    },
    handleTransposeStartInput(col, value) {
      const num = Number(value);
      if (isNaN(num)) {
        this.groupTypes[col].start = 1;
        this.groupTypes[col].cur = 1;
      } else {
        this.groupTypes[col].start = num;
        this.groupTypes[col].num = num;
      }
      this.saveGroupingTypes();
    },
    isGroupModeSelected(field, mode) {
      return this.groupTypes?.[field]?.value === mode;
    },
    handleGrouping(field) {
      const group = this.groupFields.find((f) => f === field);
      if (group) {
        this.groupFields = this.groupFields.filter((f) => f !== field);
      } else {
        this.groupFields.push(field);
      }
      this.saveGroupingFields();
    },
    saveGroupingFields() {
      const hash = this.getSourceHash();
      localStorage.setItem(
        `grouping-fields-${hash}`,
        JSON.stringify(this.groupFields)
      );
    },
    handleGroupingType(field, type) {
      const mode = this.groupModes.find((g) => g.value === type);
      const types = JSON.parse(JSON.stringify(this.groupTypes));
      types[field] = mode;
      this.groupTypes = types;
      this.saveGroupingTypes();
    },
    saveGroupingTypes() {
      const hash = this.getSourceHash();
      localStorage.setItem(
        `grouping-types-${hash}`,
        JSON.stringify(this.groupTypes)
      );
    },
    getPreview(col) {
      if (!col) return;
      const mapping = this.mappings?.[col];
      if (!mapping) return '';
      if (mapping.custom) return mapping.custom;
      const value =
        this.sourceContent.find((item) => item?.[mapping.col])?.[mapping.col] ??
        '';
      return value;
    },
    process(download) {
      const vm = this;
      if (this.mode !== 'preview') {
        let content = JSON.parse(JSON.stringify(this.sourceContent));
        if (this.groupFields.length) {
          content = this.group(content);
        }
        let rows = content.reduce((items, item) => {
          const mappedItem = vm.targetHeaders.reduce((obj, h) => {
            obj[h] = null;
            if (vm.mappings?.[h]) {
              const mapping = vm.mappings[h];
              if (mapping.custom && mapping.custom.toString().trim() !== '') {
                obj[h] = mapping.custom;
              } else if (mapping.col) {
                obj[h] = item?.[mapping.col];
              } else {
                obj[h] = null;
              }
            }
            const transposeHeader = `~~transpose-target~~${h}`;
            if (item?.[transposeHeader]) {
              const target = transposeHeader.replace(
                '~~transpose-target~~',
                ''
              );
              obj[target] = item[transposeHeader];
            }
            return obj;
          }, {});
          items.push(mappedItem);
          return items;
        }, []);
        this.mappedResult = rows;
      }
      const result = Papa.unparse(this.mappedResult, {
        ...this.exportSettings,
      });
      if (download) {
        this.downloadFile('result.csv', result);
      }
    },
    group(rows) {
      const vm = this;
      const counts = {};
      const grouped = rows.reduce((list, row) => {
        const key = vm.groupFields.map((f) => row[f]).join('~');
        if (!list[key]) {
          list[key] = row;
          for (const col in this.groupTypes) {
            const mode = this.groupTypes[col]?.value ?? null;
            switch (mode) {
              case 'add':
              case 'subtract':
                list[key][col] = Number(list[key][col]);
                break;
              case 'count':
                list[key][col] = this.groupTypes[col].start;
                break;
              case 'transpose': {
                const targetCol =
                  '~~transpose-target~~' +
                  this.groupTypes[col].target.replace(
                    '{%i}',
                    this.groupTypes[col].start
                  );
                if (!counts[col]) counts[col] = {};
                counts[col][key] = this.groupTypes[col].start;
                list[key][targetCol] = row[col];
                break;
              }
            }
          }
        } else {
          for (const col in this.groupTypes) {
            const mode = this.groupTypes[col]?.value ?? null;
            if (!mode) return;
            switch (mode) {
              case 'add':
                list[key][col] += Number(row[col]);
                break;
              case 'subtract':
                list[key][col] -= Number(row[col]);
                break;
              case 'last':
                list[key][col] = row[col];
                break;
              case 'count':
                list[key][col]++;
                break;
              case 'concat':
                list[key][col] += this.groupTypes[col].glue + row[col];
                break;
              case 'transpose': {
                counts[col][key]++;
                const targetCol =
                  '~~transpose-target~~' +
                  this.groupTypes[col].target.replace('{%i}', counts[col][key]);
                list[key][targetCol] = row[col];
                break;
              }
            }
          }
        }
        return list;
      }, {});

      const result = [];
      for (const group in grouped) {
        result.push(grouped[group]);
      }
      return result;
    },
    togglePreview() {
      if (this.mode !== 'preview') {
        this.process();
        this.mode = 'preview';
      }
    },
    toggleGrouping() {
      this.showLeftSidebar = !this.showLeftSidebar;
      this.process();
    },
    getSettingsHash() {
      const fields = [...this.sourceHeaders, ...this.targetHeaders];
      return sum(fields);
    },
    getSourceHash() {
      const fields = [...this.sourceHeaders];
      return sum(fields);
    },
    getTargetHash() {
      const fields = [...this.targetHeaders];
      return sum(fields);
    },
    setMapping(target, source) {
      this.mappings[target].col = source;
      this.mappings[target].custom = null;
      this.saveMappings();
    },
    setCustomMapping(target, source) {
      this.mappings[target].col = null;
      this.mappings[target].custom = source;
      this.saveMappings();
    },
    saveMappings() {
      const hash = this.getSettingsHash();
      localStorage.setItem(`mapping-${hash}`, JSON.stringify(this.mappings));
    },
    dropSource(e) {
      const vm = this;
      const name = e.dataTransfer?.files?.[0]?.name ?? 'unknown';
      Papa.parse(e.dataTransfer.files[0], {
        header: true,
        skipEmptyLines: true,
        complete(result) {
          vm.sourceFilename = name;
          if (result?.meta?.fields?.length > 0) {
            vm.sourceHeaders = result.meta.fields;
            vm.sourceContent = result.data;
            vm.loadMapping();
            vm.loadGrouping();
          }
        },
      });
    },
    handleSourceSelect(e) {
      const vm = this;
      const name = e.target?.files?.[0]?.name ?? 'unknown';
      Papa.parse(e.target.files[0], {
        header: true,
        skipEmptyLines: true,
        complete(result) {
          vm.sourceFilename = name;
          if (result?.meta?.fields?.length > 0) {
            vm.sourceHeaders = result.meta.fields;
            vm.sourceContent = result.data;
            vm.loadMapping();
            vm.loadGrouping();
          }
        },
      });
    },
    handleTargetSelect(e) {
      const vm = this;
      const name = e.target?.files?.[0]?.name ?? 'unknown';
      Papa.parse(e.target.files[0], {
        header: true,
        skipEmptyLines: true,
        complete(result) {
          vm.targetFilename = name;
          if (result?.meta?.fields?.length > 0) {
            vm.targetHeaders = result.meta.fields;
            if (!vm.loadMapping()) {
              vm.mappings = Object.fromEntries(
                result.meta.fields.map((f) => [f, { col: null, custom: null }])
              );
            }
          }
        },
      });
    },
    dropTarget(e) {
      const vm = this;
      const name = e.dataTransfer?.files?.[0]?.name ?? 'unknown';
      Papa.parse(e.dataTransfer.files[0], {
        header: true,
        skipEmptyLines: true,
        complete(result) {
          vm.targetFilename = name;
          if (result?.meta?.fields?.length > 0) {
            vm.targetHeaders = result.meta.fields;
            if (!vm.loadMapping()) {
              vm.mappings = Object.fromEntries(
                result.meta.fields.map((f) => [f, { col: null, custom: null }])
              );
            }
          }
        },
      });
    },
    loadMapping() {
      if (!this.sourceHeaders.length || !this.targetHeaders.length)
        return false;
      const hash = this.getSettingsHash();
      const rawMappings = localStorage.getItem(`mapping-${hash}`);
      if (rawMappings) {
        const mappings = JSON.parse(rawMappings);
        const keys = Object.keys(mappings);
        const keyHash = sum(keys);
        if (keyHash === this.getTargetHash()) {
          this.mappings = mappings;
          this.mappingLoaded = true;
          return true;
        }
      }
      return false;
    },
    loadGrouping() {
      const hash = this.getSourceHash();
      const rawTypes = localStorage.getItem(`grouping-types-${hash}`);
      const rawFields = localStorage.getItem(`grouping-fields-${hash}`);

      if (rawTypes) {
        const types = JSON.parse(rawTypes);
        const cleanTypes = {};
        for (const field in types) {
          if (this.sourceHeaders.includes(field)) {
            cleanTypes[field] = types[field];
          }
        }
        this.groupTypes = cleanTypes;
        this.groupTypesLoaded = true;
      }
      if (rawFields) {
        const fields = JSON.parse(rawFields);
        this.groupFields =
          fields?.filter?.((f) => this.sourceHeaders.includes(f)) ?? [];
        this.groupFieldsLoaded = true;
      }
    },
    parseFile(e) {
      const vm = this;
      Papa.parse(e.target.files[0], {
        header: true,
        skipEmptyLines: true,
        complete(result) {
          if (result?.meta?.fields?.length > 0) {
            vm.sourceHeaders = result.meta.fields;
          }
        },
      });
    },
    downloadFile(filename, content) {
      const blob = new Blob([content], {
        type: 'text/plain',
      });

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        const e = document.createEvent('MouseEvents');
        const a = document.createElement('a');
        a.download = filename;
        a.href = window.URL.createObjectURL(blob);
        a.dataset.downloadurl = ['text/plain', a.download, a.href].join(':');
        e.initEvent(
          'click',
          true,
          false,
          window,
          0,
          0,
          0,
          0,
          0,
          false,
          false,
          false,
          false,
          0,
          null
        );
        a.dispatchEvent(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  --neg-one: -1;
  --black: #242424;
  --transp-black: rgba(0, 0, 0, 0.3);

  will-change: padding-left;
  transition: padding-left 0.3s ease-in-out;

  .dropzones-container {
    display: flex;

    .dropzone {
      background-color: black;
      color: white;
      text-transform: uppercase;
      font-weight: bold;
      clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);

      label {
        input[type='file'] {
          display: none;
        }
      }
    }
  }

  .hexagons-container {
    display: flex;
    flex-direction: column;

    .hexagon-row {
      display: flex;
      gap: calc(var(--width) * 0.68);
      will-change: margin-top, padding-left;
      transition: all 0.3s ease-in-out;

      &:nth-child(even) {
        margin-top: calc(var(--height) * 0.5 * var(--neg-one));
        padding-left: calc(var(--width) * 0.84);
      }

      &:nth-child(odd) {
        margin-top: calc(var(--height) * 0.5 * var(--neg-one));
      }

      &:first-child {
        margin-top: 0;
      }

      .hexagon {
        clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
        display: flex;
        align-items: center;
        justify-content: center;
        width: var(--width);
        height: var(--height);
        cursor: pointer;
        background-color: var(--gray, gray);
        text-transform: uppercase;
        color: var(--white, white);
        font-size: var(--font-size, 1rem);
        will-change: background-color, width, height, font-size;
        transition: all 0.3s ease-in-out;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:hover {
          background-color: var(--black, black);
        }

        &.target,
        &.source {
          background-color: var(--gray, gray);
          &.active {
            background-color: var(--black, black);
          }
        }
      }
    }
  }

  .buttons {
    display: inline-flex;
    margin: 0 auto;
    margin-top: calc(50vh - calc(var(--height) / 2));
    will-change: margin-top;
    transition: margin-top 0.3s ease-in-out;

    &.init {
      margin-top: 0;
    }
  }

  table {
    border-collapse: collapse;
    tr {
      th {
        text-transform: uppercase;
        padding: 10px 10px;
        text-align: left;
        background-color: var(--black, black);
        color: var(--white, white);
      }

      td {
        text-align: left;
        padding: 10px 10px;

        &.target {
          padding: 0 10px;
        }

        &.inputs {
          > div {
            display: flex;
            gap: 10px;
          }
        }
      }
    }

    &.preview {
      tr {
        td,
        th {
          white-space: nowrap;
        }
      }
    }
  }

  .grouping,
  .mapping,
  .export-settings {
    background-color: var(--white, white);
    box-shadow: 0 0 20px var(--transp-black, black);
    table {
      margin: 0 auto;
    }
  }
  .export-settings {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    label {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      text-transform: uppercase;
    }
  }
}
</style>
